<template>
  <v-card
    flat
    outlined
  >
    <v-card-title v-text="title" />
    <v-card-subtitle
      v-if="subtitle"
      v-text="subtitle"
    />
    <v-card-actions>
      <v-btn
        block
        color="primary"
        depressed
        v-text="'Seleccionar'"
        :to="to"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "AmbulanciasBtn",
  props: {
    to: String,
    title: String,
    subtitle: String,
  },
};
</script>