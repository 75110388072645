<template>
  <section class="AmbulanciasMain empresas__inner container mt24 mb24">
    <ButtonBackHome />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">
        ¿Cuál es el tipo de servicio de ambulancia requerido?
      </h2>
      <div class="empresas_home__text">
        Aquí podrás realizar la reservación de los servicios de ambulancias para
        eventos especiales, traslados locales y traslados foráneos.<br /><span style="background: #ecb731">Algunos servicios de ambulancias deberán ser pagados por lo menos con
          48 horas de anticipación para poder apoyarte.</span>
      </div>
    </header>
    <ul class="ambulancias_seleccionar__list row">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <li class="ambulancias_seleccionar__item">
            <div class="
                ambulancias_seleccionar__item-icon
                ambulancias_seleccionar__item-icon--interior
              ">
              <svg
                width="49"
                height="49"
                viewBox="0 0 24 24"
              >
                <path d="M2,22l14-5L7,8L2,22z M12.35,16.18L5.3,18.7l2.52-7.05L12.35,16.18z" />
                <path d="M14.53,12.53l5.59-5.59c0.49-0.49,1.28-0.49,1.77,0l0.59,0.59l1.06-1.06l-0.59-0.59c-1.07-1.07-2.82-1.07-3.89,0 l-5.59,5.59L14.53,12.53z" />
                <path d="M10.06,6.88L9.47,7.47l1.06,1.06l0.59-0.59c1.07-1.07,1.07-2.82,0-3.89l-0.59-0.59L9.47,4.53l0.59,0.59 C10.54,5.6,10.54,6.4,10.06,6.88z" />
                <path d="M17.06,11.88l-1.59,1.59l1.06,1.06l1.59-1.59c0.49-0.49,1.28-0.49,1.77,0l1.61,1.61l1.06-1.06l-1.61-1.61 C19.87,10.81,18.13,10.81,17.06,11.88z" />
                <path d="M15.06,5.88l-3.59,3.59l1.06,1.06l3.59-3.59c1.07-1.07,1.07-2.82,0-3.89l-1.59-1.59l-1.06,1.06l1.59,1.59 C15.54,4.6,15.54,5.4,15.06,5.88z" />
              </svg>
            </div>
            <h3 class="ambulancias_seleccionar__item-heading">
              Eventos<br />especiales
            </h3>
            <div class="ambulancias_seleccionar__item-text">
              Te acompañamos en los eventos donde sea requerida la asistencia de
              una o más ambulancias
            </div>
            <v-btn
              class="primary"
              depressed
              to="/empresas/ambulancia/serviciosespeciales"
            >Seleccionar <v-icon>chevron_right</v-icon>
            </v-btn>
          </li>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <li class="ambulancias_seleccionar__item">
            <div class="
                ambulancias_seleccionar__item-icon
                ambulancias_seleccionar__item-icon--design
              ">
              <svg
                width="49"
                height="49"
                viewBox="0 0 24 24"
              >
                <path d="M12,3L1,11.4l1.21,1.59L4,11.62V21h16v-9.38l1.79,1.36L23,11.4L12,3z M18,19H6v-8.9l6-4.58l6,4.58V19z M9,14 c0,0.55-0.45,1-1,1s-1-0.45-1-1c0-0.55,0.45-1,1-1S9,13.45,9,14z M12,13c0.55,0,1,0.45,1,1c0,0.55-0.45,1-1,1s-1-0.45-1-1 C11,13.45,11.45,13,12,13z M15,14c0-0.55,0.45-1,1-1s1,0.45,1,1c0,0.55-0.45,1-1,1S15,14.55,15,14z" />
              </svg>
            </div>
            <h3 class="ambulancias_seleccionar__item-heading">
              Traslados<br />locales
            </h3>
            <div class="ambulancias_seleccionar__item-text">
              Te apoyamos a trasladar a pacientes dentro de la ciudad de
              Chihuahua, Chih.
            </div>
            <v-btn
              class="primary"
              depressed
              to="/empresas/ambulancia/trasladoslocales"
            >Seleccionar <v-icon>chevron_right</v-icon>
            </v-btn>
          </li>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <li class="ambulancias_seleccionar__item">
            <div class="
                ambulancias_seleccionar__item-icon
                ambulancias_seleccionar__item-icon--furniture
              ">
              <svg
                width="49"
                height="49"
                viewBox="0 0 24 24"
              >
                <path d="M19,15.18V7c0-2.21-1.79-4-4-4s-4,1.79-4,4v10c0,1.1-0.9,2-2,2s-2-0.9-2-2V8.82C8.16,8.4,9,7.3,9,6c0-1.66-1.34-3-3-3 S3,4.34,3,6c0,1.3,0.84,2.4,2,2.82V17c0,2.21,1.79,4,4,4s4-1.79,4-4V7c0-1.1,0.9-2,2-2s2,0.9,2,2v8.18c-1.16,0.41-2,1.51-2,2.82 c0,1.66,1.34,3,3,3s3-1.34,3-3C21,16.7,20.16,15.6,19,15.18z M6,7C5.45,7,5,6.55,5,6s0.45-1,1-1s1,0.45,1,1S6.55,7,6,7z M18,19 c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S18.55,19,18,19z" />
              </svg>
            </div>
            <h3 class="ambulancias_seleccionar__item-heading">
              Traslados<br />Foráneos
            </h3>
            <div class="ambulancias_seleccionar__item-text">
              Trasladamos a pacientes fuera de la ciudad de Chihuahua, Chih.
            </div>
            <v-btn
              class="primary"
              depressed
              to="/empresas/ambulancia/trasladosforaneos"
            >Seleccionar <v-icon>chevron_right</v-icon>
            </v-btn>
          </li>
        </v-col>
      </v-row>
    </ul>
  </section>
</template>
<script>
import standard from "@/mixins/standard";
import HeaderTitle from "@/components/header/HeaderTitle.vue";
import AmbulanciasBtn from "@/components/ambulancias/AmbulanciasBtn";
import AmbulanciasReservacionesListado from "@/components/ambulancias/AmbulanciasReservacionesListado";
export default {
  name: "Ambulancias",
  mixins: [standard],
  components: {
    HeaderTitle,
    AmbulanciasReservacionesListado,
    AmbulanciasBtn,
  },
  data() {
    return {};
  },
};
</script>