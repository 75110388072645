<template>
  <div class="AmbulanciasReservacionesListado">
    <v-card
      flat
      :disabled="cargando"
      :loading="cargando"
    >
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Buscar"
          hide-details
        />
      </v-card-title>
      <v-data-table
        class="upper"
        :headers="headers"
        :items="reservaciones"
        :search="search"
        :loading="cargando"
        loading-text="Cargando..."
      >
        <!-- <template v-slot:item.folio="{ item }">
          {{item.id.slice(0,5)}}
        </template> -->
        <template v-slot:item.fecha="{ item }">
          {{$Datetime.fromSQL(item.fecha_inicio_eva).setLocale('es-Mx').toLocaleString($Datetime.DATE_HUGE)}}
        </template>
        <!-- <template v-slot:item.origen="{ item }">
          {{item.lugar_inicio_eva.description}}
        </template> -->
        <template v-slot:item.estatus="{ item }">
          <v-chip
            small
            :color="_estatusColor(item.estatus_cgo,item.fecha_inicio_eva)"
          >
            <span v-html="_formatEstatus(item.estatus_cgo,item.fecha_inicio_eva)" />
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            :to="'/empresas/ambulancia/detalle/'+item.id"
          >
            <v-icon v-text="'more_vert'" />
          </v-btn>

        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import getData from "@/mixins/getData";
export default {
  name: "AmbulanciasReservacionesListado",
  mixins: [getData],
  data() {
    return {};
  },
  methods: {},
  created() {
    this._getReservaciones();
  },
  watch: {
    $route() {
      if (this.$route.name === "AmbulanciasSeleccionar")
        this._getReservaciones();
    },
  },
};
</script>
